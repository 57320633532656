// Body
$body-bg: #ededed;

// Typography
$font-1: "Roboto", sans-serif;

// Colors
$blue: #004C8B;
$gray: #707070;
$orange: #ff5528;
$blue-light: #4296ca;
$white: #FFFFFF;
$blue-lighter: #4296ca;
$gray-darker: #595959;
$gray-lighter: #AAAAAA;

$media: (
  'tv': 2000px,
  'desktop': 992px,
  'beforeDesktop': 991px,
  'tablet': 768px,
  'beforeTablet': 767px,
  'mobile': 600px,
  'customContainer': 1200px,
);